import axios from 'settings/axios';

import { getUrlParameter } from 'utils';
import SEARCH_PARAMS from 'constants/searchParams';
import * as actionTypes from './actionTypes';

export const fetchRequestStart = () => ({
  type: actionTypes.FETCH_REQUEST_START,
});

export const fetchRequestSuccess = (requests, meta, paginations) => ({
  type: actionTypes.FETCH_REQUEST_SUCCESS,
  requests,
  meta,
  paginations,
});

export const fetchRequestError = error => ({
  type: actionTypes.FETCH_REQUEST_FAIL,
  error,
});

export const fetchRequest = params => async dispatch => {
  dispatch(fetchRequestStart());
  const url = `/v1/request${params}`;
  try {
    const response = await axios.get(url);
    const { data } = response.data;
    const currentPage = getUrlParameter(SEARCH_PARAMS.PAGE);
    const searchTitle = getUrlParameter(SEARCH_PARAMS.SEARCH) || '';
    const requestedBy = getUrlParameter(SEARCH_PARAMS.REQUESTED_BY) || '';
    const searchProjectTag = getUrlParameter(SEARCH_PARAMS.PROJECT_TAG) || '';
    const dateFrom = getUrlParameter(SEARCH_PARAMS.DATE_FROM) || '';
    const dateTo = getUrlParameter(SEARCH_PARAMS.DATE_TO) || '';
    let orderName = getUrlParameter(SEARCH_PARAMS.SORT_NAME) || '';
    if (orderName === 'key') {
      orderName = 'id';
    }
    const orderType = getUrlParameter(SEARCH_PARAMS.SORT_TYPE) || '';
    const searchDate = [];
    /* istanbul ignore next */
    if (dateFrom && dateTo) {
      searchDate[0] = dateFrom;
      searchDate[1] = dateTo;
    }
    const searchStatus = getUrlParameter(SEARCH_PARAMS.STATUS) || [];
    const ALL = 'all';
    const searchDept = getUrlParameter(SEARCH_PARAMS.DEPT) || ALL;

    const meta = {
      searchTitle,
      searchDate,
      searchStatus,
      searchDept,
      searchProjectTag,
      orderName,
      orderType,
      requestedBy,
    };

    const paginations = {
      currentPage,
      totalItem: data.total,
      totalPage: data.pages,
      itemPerPage: data.limit,
    };
    dispatch(fetchRequestSuccess(data, meta, paginations));
  } catch (error) {
    const { status, data } = error.response;
    dispatch(fetchRequestError({ status, data }));
  }
};
