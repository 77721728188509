import * as actionTypes from 'store/actions/actionTypes';
import { updateObject } from 'utils/utility';

const initialState = {
  loading: false,
  success: false,
  message: '',
};

const submitRequestStart = state => {
  return updateObject(state, {
    loading: true,
  });
};

const submitRequestSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    success: action.success,
    message: action.message,
  });
};

const submitRequestFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    success: action.success,
    message: action.message,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_REQUEST_ACTION_START:
    case actionTypes.ADD_MORE_REVIEWERS_START:
    case actionTypes.SUBMIT_DELEGATION_START:
    case actionTypes.SUBMIT_REQUEST_CEO_START:
      return submitRequestStart(state);
    case actionTypes.SUBMIT_REQUEST_ACTION_SUCCESS:
    case actionTypes.ADD_MORE_REVIEWERS_SUCCESS:
    case actionTypes.SUBMIT_DELEGATION_SUCCESS:
    case actionTypes.SUBMIT_REQUEST_CEO_SUCCESS:
      return submitRequestSuccess(state, action);
    case actionTypes.SUBMIT_REQUEST_ACTION_FAIL:
    case actionTypes.ADD_MORE_REVIEWERS_FAIL:
    case actionTypes.SUBMIT_DELEGATION_FAIL:
    case actionTypes.SUBMIT_REQUEST_CEO_FAIL:
      return submitRequestFail(state, action);
    default:
      return state;
  }
};

export default reducer;
