export const LANDING = '/';
export const LOGIN = '/login';
export const LOGIN_CALLBACK = '/login/callback';
export const LOGOUT_CALLBACK = '/logout/callback';
export const DASHBOARD = '/dashboard';

export const DASHBOARD_REQUEST = `${DASHBOARD}/request`;
export const DASHBOARD_REQUEST_DETAIL = `${DASHBOARD_REQUEST}/:id`;
export const DASHBOARD_REQUEST_CREATE = `${DASHBOARD_REQUEST}/create`;
export const DASHBOARD_REQUEST_EDIT = `${DASHBOARD_REQUEST}/:id/edit`;
export const DASHBOARD_REQUEST_EDIT_FINANCE_CODE = `${DASHBOARD_REQUEST}/:id/edit-finance-code`;
export const DASHBOARD_REQUEST_ACTION_DETAIL = `${DASHBOARD_REQUEST}/:id/:action`;
export const DASHBOARD_REQUEST_REIMBURSE = `${DASHBOARD_REQUEST}/:id/reimburse`;
export const DASHBOARD_REPORT = `${DASHBOARD}/report`;
export const DASHBOARD_SETTING = `${DASHBOARD}/setting`;
export const DASHBOARD_BUDGET_MANAGEMENT = `${DASHBOARD}/budget-management`;
export const DASHBOARD_BUDGET = `${DASHBOARD}/budget`;

export const REQUEST_GET_LIST_CATEGORIES = `/v1/internal/get-categories-by-department`;

export const PENDING_REQUESTS_URL = `/v1/request/pendings`;

export const YEAR_BUDGET_URL = '/v1/budget/list-year';

export const CURRENT_BUDGET_TABLE_URL = '/v1/budget/dashboard/current-budget';
export const BUDGET_CHART_URL = '/v1/budget/dashboard/statistic-budget-chart';

export const CURRENT_EPOCH_TIME = '/v1/current-time/epoch';
