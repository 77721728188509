import { updateObject } from 'utils/utility';
import * as actionTypes from '../actions/actionTypes';
import { produce } from 'immer';
import moment from 'moment';

const initialState = {
  budgetData: [],
  error: null,
  loading: false,
  year: '',
  modalData: {
    visible: false,
    modalContent: {},
    limitedBudget: null,
    startMonth: null,
    endMonth: null,
  },
};

export const fetchBudgetStart = state => {
  return updateObject(state, { loading: true });
};

const fetchBudgetSuccess = (state, action) => {
  let { budget_data } = action.data;

  const updatedAt = moment().format();

  budget_data = budget_data.map(budget => ({ ...budget, updated_at: updatedAt }));

  const updatedState = {
    budgetData: budget_data,
    year: action.data.year,
    loading: false,
  };

  return updateObject(state, updatedState);
};

const fetchBudgetError = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

export const updateBudgetStart = state => {
  return updateObject(state, { loading: true });
};

export const updateBudgetSuccess = (state, action) => {
  return produce(state, draftState => {
    const newBudget = action.budget;

    const updatedAt = moment().format();

    draftState.budgetData = draftState.budgetData.map(item => {
      if (item.department === newBudget.department) {
        return {
          ...newBudget,
          updated_at: updatedAt,
        };
      }
      return item;
    });

    draftState.loading = false;
  });
};

export const showModalBudget = (state, action) => {
  return updateObject(state, {
    modalData: {
      startMonth: null,
      endMonth: null,
      limitedBudget: 0,
      visible: true,
      modalContent: action.modalContent,
    },
  });
};

export const setLimitedBudget = (state, action) => {
  return updateObject(state, {
    modalData: {
      ...state.modalData,
      limitedBudget: action.limitedBudget,
      visible: false,
      startMonth: action.startMonth,
      endMonth: action.endMonth,
    },
  });
};
export const cancelModalBudget = state => {
  return updateObject(state, {
    modalData: {
      startMonth: null,
      endMonth: null,
      limitedBudget: 0,
      visible: false,
      modalContent: {},
    },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BUDGET_START:
      return fetchBudgetStart(state);
    case actionTypes.FETCH_BUDGET_SUCCESS:
      return fetchBudgetSuccess(state, action);
    case actionTypes.FETCH_BUDGET_FAIL:
      return fetchBudgetError(state, action);
    case actionTypes.UPDATE_BUDGET_START:
      return updateBudgetStart(state);
    case actionTypes.UPDATE_BUDGET_SUCCESS:
      return updateBudgetSuccess(state, action);
    case actionTypes.SHOW_MODAL_BUDGET:
      return showModalBudget(state, action);
    case actionTypes.CANCEL_MODAL_BUDGET:
      return cancelModalBudget(state);
    case actionTypes.SET_LIMITED_BUDGET:
      return setLimitedBudget(state, action);
    default:
      return state;
  }
};

export default reducer;
