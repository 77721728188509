import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import Decimal from 'decimal.js';

export const monthAbbreviations = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

export const getMonthColumnTitle = month => {
  const monthkey = `${monthAbbreviations[Number(month) - 1]}_budget`;
  return monthkey;
};

function areAllRecordsUpdatedAtTheSameTime(dataSource) {
  if (dataSource.length === 0) {
    return true;
  }

  const firstUpdatedAt = dataSource[0].updated_at;

  const isUpdatedAtTheSameTime = budget => budget.updated_at === firstUpdatedAt;

  return dataSource.slice(1).every(isUpdatedAtTheSameTime);
}

export const getMostRecentUpdatedBugdet = dataSource => {
  if (areAllRecordsUpdatedAtTheSameTime(dataSource)) {
    return null;
  }

  const mostRecentObject = dataSource.reduce((aggregator, budget) => {
    if (!aggregator) {
      return budget;
    }

    const updatedAt = moment(budget.updated_at);

    if (updatedAt.isAfter(moment(aggregator.updated_at))) {
      return budget;
    }

    return aggregator;
  }, null);

  return mostRecentObject;
};

export const isDate1AfterDate2 = (date1, date2) => {
  if (!date2 || date2.trim() === '') {
    return true;
  }

  const momentDate1 = moment(date1);
  const momentDate2 = moment(date2);

  return momentDate1.isAfter(momentDate2, 'second');
};

export const reChangeMonthColumnTitle = month => {
  const abbreviation = month.split('_budget')[0];
  return monthAbbreviations.indexOf(abbreviation) + 1;
};

export const getCategories = (updateBudget, monthNumber) => {
  if (isEmpty(updateBudget.children)) {
    return [];
  }
  const childrens = updateBudget.children;
  const monthBudgetCategories = [];
  childrens.map(children => {
    Object.keys(children).forEach(key => {
      if (key.endsWith('_budget')) {
        const monthNumber = reChangeMonthColumnTitle(key);
        const categoryObj = monthBudgetCategories.find(obj => obj.month === monthNumber);
        if (categoryObj) {
          categoryObj.budget.push({
            category_budget: Number(children[key]),
            category_name: children.department,
          });
        } else {
          const newCategoryObj = {
            month: monthNumber,
            budget: [
              {
                category_budget: Number(children[key]),
                category_name: children.department,
              },
            ],
          };
          monthBudgetCategories.push(newCategoryObj);
        }
      }
    });
    return null;
  });
  //.....Get the budget categories for current month.....
  const existing = monthBudgetCategories.find(obj => Number(obj.month) === Number(monthNumber));
  if (existing) {
    return existing.budget;
  }
};

export const handleParserInput = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d.]/g, '');
  const decimalPos = onlyNums.indexOf('.');
  if (decimalPos >= 0) {
    const onlyNumsSlice = onlyNums.slice(0, decimalPos + 3);
    return onlyNumsSlice;
  }
  return onlyNums;
};

export const calculateTotalInBudgetManagement = (total, item) => {
  return new Decimal(total).add(item);
};
