import { CURRENT_EPOCH_TIME } from 'constants/routes';

export const updateObject = (oldObject, updatedProperties) => {
  return { ...oldObject, ...updatedProperties };
};

export const arrayToObject = (array, mapping) => {
  return Object.assign({}, ...array.map(mapping));
};

export const calcExpiration = nextInSecond => {
  /* istanbul ignore next */
  const nowInSecond = Math.floor(Date.now() / 1000);
  /* istanbul ignore next */
  return nextInSecond - nowInSecond;
};

export const moneyFormat = (
  value,
  currency = 'USD',
  locales = 'en-US',
  options = {
    style: 'currency',
    currency,
  },
) => {
  const formatter = new Intl.NumberFormat(locales, options);

  if (Number.isNaN(value)) {
    return formatter.format(0);
  }

  return formatter.format(value);
};

export const cachedResolver = obj => {
  const sortedKeys = Object.keys(obj).sort();
  const sortedObject = {};
  for (const key of sortedKeys) {
    sortedObject[key] = obj[key];
  }
  return JSON.stringify(sortedObject);
};

export function concatURL(base, path) {
  // Ensure the base URL ends with a '/'
  if (!base.endsWith('/')) {
    base += '/';
  }

  // Ensure the path does not start with a '/'
  if (path.startsWith('/')) {
    path = path.substring(1);
  }

  return base + path;
}

export async function getCurrentEpochTimeFromServer() {
  const response = await fetch(concatURL(process.env.REACT_APP_API_URL, CURRENT_EPOCH_TIME));
  const data = await response.json();

  return data.epochTime;
}
