import * as actionTypes from 'store/actions/actionTypes';
import { updateObject } from 'utils/utility';

const initialState = {
  request: {},
  loading: true,
  error: null,
  submitted: false,
  formSubmitting: false,
  formError: null,
};

const fetchRequestEditFinanceCodelStart = state => {
  return updateObject(state, { error: null, loading: true });
};

const fetchRequestEditFinanceCodelSuccess = (state, action) => {
  return updateObject(state, {
    request: action.request,
    error: null,
    loading: false,
  });
};

const fetchRequestEditFinanceCodelFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const submitRequestEditFinanceCodeStart = state => {
  return updateObject(state, { formError: null, formSubmitting: true });
};

const submitRequestEditFinanceCodeSuccess = state => {
  return updateObject(state, {
    formError: null,
    formSubmitting: false,
    submitted: true,
  });
};

const submitRequestEditFinanceCodeFail = (state, action) => {
  return updateObject(state, {
    formError: action.error,
    formSubmitting: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REQUEST_EDIT_FINANCE_CODE_START:
      return fetchRequestEditFinanceCodelStart(state);
    case actionTypes.FETCH_REQUEST_EDIT_FINANCE_CODE_SUCCESS:
      return fetchRequestEditFinanceCodelSuccess(state, action);
    case actionTypes.FETCH_REQUEST_EDIT_FINANCE_CODE_FAIL:
      return fetchRequestEditFinanceCodelFail(state, action);
    case actionTypes.SUBMIT_REQUEST_EDIT_FINANCE_CODE_START:
      return submitRequestEditFinanceCodeStart(state);
    case actionTypes.SUBMIT_REQUEST_EDIT_FINANCE_CODE_SUCCESS:
      return submitRequestEditFinanceCodeSuccess(state);
    case actionTypes.SUBMIT_REQUEST_EDIT_FINANCE_CODE_FAIL:
      return submitRequestEditFinanceCodeFail(state, action);
    case '@@router/LOCATION_CHANGE':
      return initialState;
    default:
      return state;
  }
};

export default reducer;
