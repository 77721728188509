import axios from 'settings/axios';
import { push } from 'connected-react-router';
import { notification } from 'antd';

import { DASHBOARD_REQUEST } from 'constants/routes';
import { ACTOR_STATUSES } from 'constants/user';
import * as actionTypes from './actionTypes';

export const openNotificationWithIcon = (type, message) => {
  notification[type]({ message });
};

export const handleStatus = async (id, data) => {
  const url = `/v1/request/status/${id}`;
  return axios.post(url, data);
};

export const handleCancel = async (id, data) => {
  const url = `/v1/request/${id}`;
  return axios.delete(url, { data });
};

export const submitRequestActionStart = () => ({
  type: actionTypes.SUBMIT_REQUEST_ACTION_START,
});

export const submitRequestActionSuccess = (success, message) => ({
  type: actionTypes.SUBMIT_REQUEST_ACTION_SUCCESS,
  success,
  message,
});

export const submitRequestActionFail = (success, message) => ({
  type: actionTypes.SUBMIT_REQUEST_ACTION_FAIL,
  success,
  message,
});

export const submitRequestAction = (id, status, note, actorTypeClick, cb) => async dispatch => {
  dispatch(submitRequestActionStart());
  try {
    let response = {};
    if (status === ACTOR_STATUSES.CANCELED) {
      response = await handleCancel(id, { note });
    } else {
      response = await handleStatus(id, { status, note, actorTypeClick });
    }
    const { success, message } = response.data;
    /* istanbul ignore next */
    if (success) {
      openNotificationWithIcon('success', message);
      dispatch(submitRequestActionSuccess(success, message));
      if (cb) {
        cb();
      } else {
        dispatch(push(DASHBOARD_REQUEST));
      }
    }
  } catch (error) {
    const { success, message } = error.response.data;
    openNotificationWithIcon('error', message);
    dispatch(submitRequestActionFail(success, message));
  }
};

export const addMoreReviewersStart = () => ({
  type: actionTypes.ADD_MORE_REVIEWERS_START,
});

export const addMoreReviewersSuccess = (success, message) => ({
  type: actionTypes.ADD_MORE_REVIEWERS_SUCCESS,
  success,
  message,
});

export const addMoreReviewersFail = (success, message) => ({
  type: actionTypes.ADD_MORE_REVIEWERS_FAIL,
  success,
  message,
});

export const addMoreReviewers = (reviewers, id, title, cb) => async dispatch => {
  dispatch(addMoreReviewersStart());
  try {
    const url = '/v1/request/more-reviewers';
    const response = await axios.post(url, { reviewers, id, title });
    const { success, message } = response.data;
    /* istanbul ignore next */
    if (success) {
      openNotificationWithIcon('success', message);
      dispatch(addMoreReviewersSuccess(success, message));
      if (cb) {
        cb();
      } else {
        dispatch(push(DASHBOARD_REQUEST));
      }
    }
  } catch (error) {
    const { success, message } = error.response.data;
    openNotificationWithIcon('error', message);
    dispatch(addMoreReviewersFail(success, message));
  }
};

export const submitDelegationStart = () => ({
  type: actionTypes.SUBMIT_DELEGATION_START,
});

export const submitDelegationSuccess = (success, message) => ({
  type: actionTypes.SUBMIT_DELEGATION_SUCCESS,
  success,
  message,
});

export const submitDelegationFail = (success, message) => ({
  type: actionTypes.SUBMIT_DELEGATION_FAIL,
  success,
  message,
});

export const submitDelegation = (delegate, id, actorTypeClick, cb) => async dispatch => {
  dispatch(submitDelegationStart());
  try {
    const url = '/v1/request/add-delegate';
    const response = await axios.post(url, { delegate, id, actorTypeClick });
    const { success, message } = response.data;
    openNotificationWithIcon('success', message);
    dispatch(submitDelegationSuccess(success, message));
    if (cb) {
      cb();
    } else {
      dispatch(push(DASHBOARD_REQUEST));
    }
  } catch (error) {
    const { success, message } = error.response.data;
    openNotificationWithIcon('error', message);
    dispatch(submitDelegationFail(success, message));
  }
};

export const submitRequestCEOStart = () => {
  return {
    type: actionTypes.SUBMIT_REQUEST_CEO_START,
  };
};

export const submitRequestCEOSuccess = (success, message) => ({
  type: actionTypes.SUBMIT_REQUEST_CEO_SUCCESS,
  success,
  message,
});

export const submitRequestCEOFail = (success, message) => ({
  type: actionTypes.SUBMIT_REQUEST_CEO_FAIL,
  success,
  message,
});

export const submitRequestCEO = (id, cb) => async dispatch => {
  dispatch(submitRequestCEOStart());
  try {
    const url = '/v1/request/request-ceo';
    const response = await axios.post(url, { id });
    const { success, message } = response.data;
    openNotificationWithIcon('success', message);
    dispatch(submitRequestCEOSuccess(success, message));
    if (cb) {
      cb();
    } else {
      dispatch(push(DASHBOARD_REQUEST));
    }
  } catch (error) {
    const { success, message } = error.response.data;
    openNotificationWithIcon('error', message);
    dispatch(submitRequestCEOFail(success, message));
  }
};
