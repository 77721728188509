/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */
import React from 'react';
import { REQUEST_STATUSES } from 'constants/request';
import { moneyFormat } from './utility';

export const getUrlParameter = sParam => {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');

  let sParameterName;

  for (let i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }

  return null;
};

// TODO: This formatter is used for not only money but other data, keep in mind to optimal this
export const currencyFormatter = (value, currency) => {
  if (value === null || value === undefined) {
    return '';
  }

  const displayValue = moneyFormat(value, 'USD', 'en-US', { maximumFractionDigits: 2 });
  if (currency) {
    return `${displayValue} ${currency}`;
  }
  return displayValue;
};

export const componentDecorator = (href, text, key) => (
  <React.Fragment key={key}>
    <a href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  </React.Fragment>
);

export const editableRequest = status => {
  return [
    REQUEST_STATUSES.CREATED,
    REQUEST_STATUSES.DELEGATED,
    REQUEST_STATUSES.SUBMITTED,
    REQUEST_STATUSES.REVIEWED,
    REQUEST_STATUSES.REJECTED,
  ].includes(status);
};
