const SEARCH_PARAMS = {
  PAGE: 'page',
  LIMIT: 'limit',
  SEARCH: 'search',
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
  STATUS: 'status',
  DEPT: 'dept',
  NEED_APPROVAL: 'needApproval',
  PROJECT_TAG: 'project_tag',
  SORT_NAME: 'orderName',
  SORT_TYPE: 'orderType',
  REQUESTED_BY: 'requestedBy',
  YEAR: 'year',
  SEARCH_CATEGORY: 'category',
};

export default SEARCH_PARAMS;
