import axios from 'settings/axios';
import { reChangeMonthColumnTitle, getCategories } from 'utils/budget';
import * as actionTypes from './actionTypes';
import { notification } from 'antd';

export const fetchBudgetStart = () => ({
  type: actionTypes.FETCH_BUDGET_START,
});

export const fetchBudgetSuccess = data => {
  return {
    type: actionTypes.FETCH_BUDGET_SUCCESS,
    data,
  };
};

export const fetchBudgetError = error => ({
  type: actionTypes.FETCH_BUDGET_FAIL,
  error,
});

export const fetchBudget = year => async dispatch => {
  dispatch(fetchBudgetStart());
  try {
    const response = await axios.get('/v1/budget', {
      params: { year },
    });
    const data = response.data.data;
    dispatch(fetchBudgetSuccess(data));
  } catch (error) {
    const { status, data } = error.response;
    dispatch(fetchBudgetError({ status, data }));
  }
};

export const updateBudgetStart = () => ({
  type: actionTypes.UPDATE_BUDGET_START,
});

export const updateBudgetSuccess = budget => {
  return {
    type: actionTypes.UPDATE_BUDGET_SUCCESS,
    budget,
  };
};

/**
 *
 * @param {*} updateBudget
 * @returns updateBudget
 */

export const updateBudget = updateBudget => async (dispatch, getState) => {
  dispatch(updateBudgetStart());
  const { department, department_head, year, is_flexible } = updateBudget;

  try {
    const { budget } = getState();
    const oldBudget = budget.budgetData.find(
      budget => budget.department.toLowerCase() === updateBudget.department.toLowerCase(),
    );
    let url = '/v1/budget';
    let response;
    //Change the format data before sending to BE.........................................

    const month_budgets = Object.keys(updateBudget).reduce((result, key) => {
      if (key.endsWith('_budget')) {
        const monthNumber = reChangeMonthColumnTitle(key);
        if (!isNaN(monthNumber)) {
          result.push({
            month: monthNumber,
            month_budget: updateBudget[key],
            category_budgets: getCategories(updateBudget, monthNumber),
          });
        }
      }
      return result;
    }, []);

    // ....Add id for departments and categories..........
    month_budgets.map(monthBudget => {
      const { month } = monthBudget;
      const matchingObject = oldBudget.month_budgets.find(
        b => parseInt(b.month) === parseInt(month),
      );
      if (matchingObject.id) {
        monthBudget.id = matchingObject.id;
        const categories = matchingObject.category_budgets;
        for (let j = 0; j < categories.length; j++) {
          if (categories[j].id) {
            monthBudget.category_budgets[j].id = categories[j].id;
          }
        }
      }
      return monthBudget;
    });
    //......................................................

    const budgetData = {
      department,
      department_head: department_head === undefined ? null : department_head,
      year,
      month_budgets,
      is_flexible: is_flexible === undefined ? false : is_flexible,
    };

    response = await axios.put(url, budgetData);
    dispatch(updateBudgetSuccess(response.data.budget));
    notification.success({
      message: response.data.message,
      description: `The budget for [${department}] department is SET successfully`,
    });
  } catch (error) {
    const { data } = error.response;
    if (data.message) {
      notification.error({
        message: data.message,
        description: `Please refresh the page.`,
      });
    }
    if (data.error) {
      notification.error({
        message: 'Success: FAIL',
        description: `The budget for ${data.error._object.department} department is set to FAIL`,
      });
    }
  }
};

export const showModalBudget = modalContent => {
  return {
    type: actionTypes.SHOW_MODAL_BUDGET,
    modalContent,
  };
};

export const cancelModalBudget = () => {
  return {
    type: actionTypes.CANCEL_MODAL_BUDGET,
  };
};

export const setLimitedBudget = (limitedBudget, startMonth, endMonth) => {
  return {
    type: actionTypes.SET_LIMITED_BUDGET,
    limitedBudget,
    startMonth,
    endMonth,
  };
};
